html {
    font-family:"Poppins", sans-serif;
}
body{
    background-color: white !important;
    color: white !important;
}
h1.dark {
    color: white !important;
    margin-bottom: 15px;
}
h2.dark {
    color: white !important;
}
h3.dark {
    color: white;
    font-size: 20px;
}

*{scroll-behavior: smooth;}

h1 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 0px;
    font-family:"Poppins", sans-serif;
}
h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 1;
    font-family:"Poppins", sans-serif;
}

.full-page {
    width: 100%;
    height: auto;
    z-index: 100;
    overflow: auto;
}
.full-page-absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
}
.full-page-container {
    position: relative;
    z-index: 100;
    background-color: white;
}
.display-flex {
    display: flex;
    width: 100%;
    align-items: center;
}
.display-flex-center {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.action-button {
    border-radius: 10px !important;
    font-weight: 500 !important;
}
.button {
    background-color: rgba(86,98,226, 1) !important;
    border-color: rgba(86,98,226, 1) !important;
}
.no-desktop {
    display: none;
}
.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &&::-webkit-scrollbar {
        display: none;
    }
}
.text-ecalmers-50{
    color: rgba(86,98,226, 0.05);
}
.text-ecalmers-100{
    color: rgba(86,98,226, 0.1);
}

.text-ecalmers-200{
    color: rgba(86,98,226, 0.2);
}
.text-ecalmers-300{
    color: rgba(86,98,226, 0.3);
}
.text-ecalmers-400{
    color: rgba(86,98,226, 0.4);
}
.text-ecalmers-500{
    color: rgba(86,98,226, 0.5);
}
.text-ecalmers-600{
    color: rgba(86,98,226, 0.6);
}
.text-ecalmers-700{
    color: rgba(86,98,226, 0.7);
}
.text-ecalmers-800{
    color: rgba(86,98,226, 0.8);
}
.text-ecalmers-900{
    color: rgba(86,98,226, 0.9);
}
.text-ecalmers-full{
    color: rgba(86,98,226, 1);
}
.text-ecalmers-full-light {
    color: rgba(86,98,226, 1);
}
.bg-ecalmers-20 {
    background-color: rgba(86,98,226, 0.02);
}
.bg-ecalmers-50 {
    background-color: rgba(86,98,226, 0.05);
}
.bg-ecalmers-100 {
    background-color: rgba(86,98,226, 0.1);
}
.bg-ecalmers-200 {
    background-color: rgba(86,98,226, 0.2);
}
.bg-ecalmers-500 {
    background-color: rgba(86,98,226, 0.5);
}
.bg-ecalmers-900 {
    background-color: rgba(86,98,226, 0.8);
}
.bg-brand-ai {
    background-color: rgba(86,98,226, 1);
}
.border-ecalmers-200 {
    border: 1px solid rgba(86,98,226, 0.2);
}
.border-ecalmers-400 {
    border: 1px solid rgba(86,98,226, 0.4) !important;
}
.border-ecalmers-500 {
    border: 1px solid rgba(86,98,226, 0.5);
}
.border-ecalmers-600 {
    border: 1px solid rgba(86,98,226, 0.6);
}
.border-ecalmers-400-hover:hover {
    border: 1px solid rgba(86,98,226, 0.4);
    transition: 1s;
}
.border-ecalmers-full {
    border: 1px solid rgba(86,98,226, 1);
}
.border-ecalmers-full-light {
    border: 1px solid rgba(86,98,226, 1);
}
.hover-border-ecalmers-full:hover {
    border: 1px solid rgba(86,98,226, 1);
}
table tr:first-child th {
  border-top-width: 0;
}
table tr td:first-child,
table tr th:first-child {
  border-left-width: 0;
}
table tr td:last-child,
table tr th:last-child {
  border-right-width: 0;
}
table tr:last-child td {
    border-bottom-width: 0;
}  
table tr:last-child td:last-child {
    border-bottom-right-radius: 20px;
}
.ant-tabs {
    height: 100%;
}
.ant-tabs-content-holder {
    display: flex;
    position: relative;
}

.hover-scale-sm:hover {
    scale: 1.02
}
.avatar-large {
    width: 80px;
    height: 80px;
}
.navbar-container {
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
}
.navbar-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 100;
}
.navbar-sticky {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 100;
}
.navbar-brand{
    padding-left: 55px;
}
.bottom-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
    text-align: center;
    padding: 20px;
    background-color: rgb(0,0,0);
    color: white;
}
.text-align-left {
    text-align: left;
}
.home-background {
    background-color: #343541 !important;
}
.half-width-section {
    width: 50%;
}
.hover-scale-xs:hover {
    scale: 1.01;
}
.hover-scale-sm:hover {
    scale: 1.03;
}
.image-div {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.image-meme-container {
    font-size: 1.5em;
    padding: 15px 10px 15px 10px;
    line-height: 1.3;
}
.image-square{
    width: 100%;
    aspect-ratio: 1/1;
}
.square {
    aspect-ratio: 1/1;
}
.width-45 {
    width: 45%;
    margin: auto;
}
.icon-backdrop {
    background-color: white;
    border-radius: 10px;
    padding: 5px;
}
.icon-container {
    padding: 5px;
}

.brand-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.brand-select-card {
    text-align: center;
    width: 100%;
    margin-bottom: 5px;
    cursor: pointer;
}
.brand-select-card:hover {
    scale: 1.02
}
.brand-avatar-upload-image {
    width: 100%;
    aspect-ratio: 1/1;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 10px;
    cursor: pointer;
}
.card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    width: 100%;
    z-index: 1;
}
.overlay-visible {
    background-color: rgba(0,0,0,0.4);
    color: white;
    font-weight: 600;
}
.display-hover {
    background-color: rgba(0,0,0,0);
    color: rgba(0,0,0,0);
}
.display-hover:hover {
    background-color: rgba(0,0,0,0.4);
    color: white;
    font-weight: 600;
}
.brand-select-card-avatar {
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 15px !important;
}
.brand-select-card-text {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
    padding-top: 5px;
}
.feed-create-modal-md {
    width: 600px;
}
.feed-create-modal-header {
    margin-bottom: 20px;
    text-align: center;
}
.feed-create-modal-back-button {
    cursor: pointer;
    position: absolute;
    left: 20px;
    font-size: 18px;
    line-height: 1;
    color: rgba(0,0,0,0.6);
}
.channel-select-icon {
    width: 80px;
    height: 80px;
    font-size: 40px;
    border: 1px solid rgb(0,0,0,0.1);
    border-radius: 15px;
    margin: auto;
    position: relative;
}
.dashboard-menu-button-container {
    padding: 5px;
}
.dashboard-menu-button {
    color: rgba(86,98,226, 1);
    background-color: white;
    border: 1px solid rgba(86,98,226, 1);
    border-radius: 10px;
    display: flex;
    align-items: center;
}
.dashboard-menu-button:hover {
    background-color: rgba(86,98,226, 0.1) !important;
    color: rgba(86,98,226, 1) !important;
}
.dashboard-menu-button.active {
    background-color: rgba(86,98,226,0.8);
    border: 1px solid rgba(86,98,226,0.8);
    font-weight: 600;
    color: white;
}
.dashboard-menu-button.active:hover {
    background-color: rgba(86,98,226,1) !important;
    border: 1px solid rgba(86,98,226, 1) !important;
    color: white !important;
}
.dashboard-menu-button-icon {
    font-size: 18px;
}
.container-fixed-left {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50%;
}
.container-scroll-right {
    margin-left: 50%;
    background-color: white;
}
.checkout-navbar {
    position: fixed;
    top:0;
    left:0;
    width:50%;
    padding:20px 0 20px 0;
}

.checkout-detail-container {
    width: 80%;
    text-align: left;
    padding-bottom: 10vh;
}
.checkout-form-container {
    padding-top: 5vh;
    padding-left: 15px;
    width: 80%;
    padding-bottom: 10vh;
    text-align: left;
}
.checkout-button-container {
    position: fixed;
    bottom: 0;
    left: 50%;
    right: 0;
    padding: 15px;
    background-color: #343541;
}
.form-header-container {
    text-align: left;
    color: #343541;
    padding:15px;
}
.form-title {
    font-size: 1.6em;
    font-weight: 700;
}
.form-description {
    font-size: 1.2em;
    padding-top: 2px;
}
.ai-gen-link {
    font-size: 10px;
    color: #5662e2;
    cursor: pointer;
}
.font-select {
    width: 100%;
    border: 1px solid rgb(0,0,0,0.1);
    border-radius: 10px;
    cursor: pointer;
    max-width: 300px;
}

.brand-button-solid {
    color: white;
    border: 1px solid #5662e2;
    background-color: #5662e2;
}
.brand-button-solid:hover {
    background-color: rgba(86,98,226, 1) !important;
    scale: 1.02;
}
.brand-button-outline {
    color: #5662e2 !important;
    border: 1px solid #5662e2;
    background-color: white;
}
.brand-button-outline:hover {
    background-color: rgba(86,98,226, 0.1) !important;
    scale: 1.05;
}
.checkout-brand-preview-card {
    padding: 20px 15px 20px 15px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 10px;
}
.checkout-brand-preview-title {
    font-size: 18px;
    font-weight: 600;
}
.checkout-product-preview-container {
    border: 1px solid rgba(86,98,226, 0.3);
    border-radius: 15px;
    padding: 5px 10px 5px 10px;
    position: relative;
}
.checkout-product-preview-image {
    width: 60px;
    aspect-ratio: 1/1;
    padding: 10px 10px 10px 0;
}
.checkout-product-preview-name {
    font-size: 16px !important;
    line-height: 1.2;
}
.checkout-product-preview-price {
    font-size: 16px !important;
}
.checkout-product-remove {
    color: red;
    position: absolute;
    cursor: pointer;
    right: -20px;
}
.checkout-product-add {
    color:#0075ff;
    position: absolute;
    cursor: pointer;
    right: -30px;
    display: flex;
    align-items: center;

}

.company-page-container {
    width: 100%;
    padding-top: 60px;
}
.company-page-side-container {
    min-height: 100vh;
    width: 20%;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    padding-left: 50px;
    padding-bottom: 250px;
    position: sticky;
    overflow-y: auto;
    white-space: nowrap;
    z-index: 1;
}
.company-page-feed-container {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    color: rgba(0,0,0,0.8);
    min-height: 100vh;
    text-align: left;
    padding: 10px;
    padding-left: 80px;
    padding-top: 20px;
}
.company-page-filter-container {
    width: 30%;
    display: inline-block;
    vertical-align: top;
    color: rgba(0,0,0,0.8);
}
.company-page-feed-tag-container {
    display: flex;
    padding: 15px;
    text-align: left;
    position: fixed;
    top: 50px;
    left: 20%;
    right: 30%;
    background-color: white;
    z-index: 50;
    padding-left: 80px;
}
.company-page-feed-content-container {
    padding-top: 50px;
}
.company-page-tag {
    padding: 10px;
    font-size: 14px;
    border: 1px solid rgba(0,0,0,0.8);
    border-radius: 15px;
}
.company-page-main-container {
    width: 80%;
    display: inline-block;
    vertical-align: top;
    min-height: 100vh;
}
.company-page-sidebar-title {
    padding-bottom: 20px;
    font-size: 20px;
    color: rgb(0,0,0,0.8);
    padding: 15px;
}
.company-page-sidebar-button {
    padding-bottom: 10px;
    font-size: 16px;
    color: rgb(0,0,0,0.8);
    padding: 15px;
    cursor: pointer;
}
.company-page-sidebar-button:hover {
    background-color: rgb(0,0,0,0.05);
    border-radius: 10px;
}
.company-page-sidebar-bottom-info {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 20%;
    padding: 10px;
    padding-left: 20px;
    background-color: white;
}
.company-page-button-active {
    background-color: rgb(0,0,0,0.05);
    border-radius: 10px;
}
.company-page-content {
    min-height: 50px;
    width: 80%;
    min-width: 800px;
    text-align: left;
    padding: 20px;
    padding-left: 100px;
    color:#1f2023 !important;
    padding-bottom: 150px;
}
.company-page-section {
    color:#1f2023 !important;
}
.company-page-detail-container {
    text-align: left;
    color: rgba(0,0,0,0.8);
    text-wrap: wrap;
    padding-top: 50px;
}
.company-page-posts-container {
    color: rgba(0,0,0,0.8);
}
.company-page-post-container {
    margin-bottom: 5px;
    padding: 5px;
}
.company-page-post-tag {
    padding: 5px;
}
.company-page-company-info-section {
    margin-bottom: 5vh;
    font-size: 18px;
    font-weight: 600;
    color: rgba(0,0,0,0.8) !important;
}
.company-page-company-info {
    margin-bottom: 5px;
    font-size: 14px;
    padding: 10px;
    padding-bottom: 5px;
    padding-right: 15px;
    border: 1px solid rgb(0,0,0,0.1);
    border-radius: 15px;
    width: fit-content;
    min-width: 40%;
}
.company-page-detail {
    font-size: 16px;
    padding: 5px;
    padding-bottom: 15px;
}
.company-page-brand-info-container{
    padding: 15px 0 15px 0;
    display: flex;
    align-items: center;
    position: fixed;
    left: 50px;
    top: 50px;
    width: 20%;
    background-color: white;
}
.company-page-detail-image {
    width: 40px;
    display: inline-block;
    margin-right: 5px;
}
.company-page-company-logo {
    height: 40px !important;
    width: 40px !important;
    border: 1px solid rgb(0,0,0,0.1);
    border-radius: 10px;
}
.company-page-company-name {
    font-size: 15px;
    font-weight: 600;
    color: black;
}
.company-page-detail-content {
    display: inline-block;
    vertical-align: top;
    padding: 5px;
    padding-left: 10px;
}
.company-page-company-tagline {
    font-size: 18px;
    font-weight: 400;
}
.company-page-company-description {
    font-size: 15px;
    font-weight: 400;
}
.company-page-post-samples {
    width: 75%;
    margin-top: 15px;
    padding-bottom: 30px;
}
.company-page-post-header {
    padding: 5px;
    font-size: 15px;
    font-weight: 600;
    color: rgb(0,0,0,0.7);
}
.company-page-post-section {
    margin-bottom: 10px;
}
.company-page-post-links {
    line-height: 1.4;
    padding: 5px;
}
.content-card-container {
    background-color: white;
    border-radius: 10px;
    height: 100%;
    cursor: pointer;
    text-align: left;
    color: rgba(0,0,0,0.8);
}
.drawer-bottom-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 10px 10px 35px;
    background-color: white;
    border-top: 1px solid rgb(0,0,0,0.05);
}
.filter-container{
    padding: 5px 10px 5px 10px;
    border: 1px solid rgba(86,98,226, 0.5);
    border-radius: 5px;
    display: flex;
    width: fit-content;
    align-items: center;
}
.filter-text {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-right: 10px;
}
.filter-dropdown-icon{
    font-size: 14px;
}
.filter-dropdown-container {
    background-color: white;
    padding: 15px;
    min-width: 200px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.01), 0 1px 5px 0 rgba(0, 0, 0, 0.03);
}
.filter-dropdown-title {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 500;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding-bottom: 5px;
    margin-bottom: 5px;
}
.filter-dropdown-option {

}

.generate-button-container {
    position: fixed;
    right: 45px;
    bottom: 80px;
}

.home-page-banner-container {
    height: 90vh;
    display: flex;
}
.home-page-left-container {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.home-page-right-container {
    width: 50%;
    text-align: left;
}
.home-page-left-content {
    width: fit-content;
    margin: auto;
}
.home-page-right-content {
    position: sticky;
    top: 100px;
    width: 80%;
    margin:auto;
}
.home-page-banner {
    padding-bottom: 15vh;
    color: rgba(0,0,0,0.8);
    text-align: left;    
}
.home-page-banner-content {
    width: 80%;
}
.home-page-title {
    font-size: 42px;
    font-weight: 600;
    margin-bottom: 5px;
}
.home-page-subtitle {
    font-size: 28px;
    line-height: 1.5;
}
.home-page-content-section {
    padding-top: 5vh;
    padding-bottom: 12vh;
}
.home-page-title-container {
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    width: 100%;
}
.home-page-details-container {
    width:80%;
    margin: auto;
    display: flex;
    color: rgb(0,0,0,0.8);
    text-align: left;
}
.home-page-details-section {
    width: 33%;
    padding: 20px;
}
.home-page-detail-card {
    border: 1px solid rgba(6, 4, 4, 0.1);
    border-radius: 10px;
    overflow: hidden;
    background-color: white;
}
.home-page-detail-card-title-container {
    padding: 15px;
}
.home-page-video-container {
    aspect-ratio: 1/1;
    overflow: hidden;
    border-top: 1px solid rgba(6, 4, 4, 0.1);
    border-bottom: 1px solid rgba(6, 4, 4, 0.1);
}
.home-page-video {
    width: 100%;
}
.home-page-video-description {
    font-size: 15px;
    padding: 15px 10px 20px 10px;
}
.home-page-get-started-section {
    background-color: rgba(57,187,236,0.05);
}
.home-page-form-container {
    border-radius: 15px;
    border: 1px solid rgba(0,0,0,0.1);
    background-color: white;
    color: rgba(0,0,0,0.8);
    font-weight: 500;
    padding: 25px 20px 10px 20px;
}
.home-page-brand-card {
    width: 25%;
    display: inline-block;
    padding: 20px;
    cursor: pointer;
}
.modal-content {
    padding: 25px;
    padding-top: 15px;
    padding-bottom: 30px;
}
.modal-titles {
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
}
.modal-text {
    font-size: 16px;
    font-size: 600;
    padding-bottom: 0px;
}
.modal-submit {
    border-radius: 5px !important;
    background-color: #5433FF;
    margin-top: 5px;
    margin-bottom: 10px;
}
.notification-container {
    background-color: #FFFAF1;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    cursor: pointer;
}
.notification-container:hover {
    
}
.search-input {
    background-color: rgba(0,0,0,0.05);
    padding: 5px 10px 5px 10px !important;
    width: 100% !important;
}
.social-media-tag {
    border: 1px solid rgba(0,0,0,0.1);
    padding: 8px 12px 8px 12px;
    border-radius: 15px;
    margin-right: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.stripe-chart-card {
    width: 100%;
    aspect-ratio: 5/2;
    padding: 5px 35px 30px 25px;
    background-color: #ffffff;
    border-radius: 10px;
    max-height: 250px;
}
.stripe-chart-title-container {
    border-bottom: 1px solid rgba(0,0,0,0.2);
    padding: 20px;
}
.stripe-chart-title {
    font-weight: 600;
    font-size: 18px;
    text-align: left;
    color: #526373;
    flex: 1;
}
.stripe-chart-logo {
    height: 30px;
}
.stripe-chart-metric-card {
    text-align: left;
    padding: 10px 20px 0 20px;
}
.stripe-chart-metric-title {
    color: #425567;
}
.stripe-chart-metric-value {
    color: #5662e2;  
    font-size: 23px;
}
.home-page-section {
    padding-top: 10vh;
    padding-bottom: 15vh;
    width: 100%;
}

.bordered {
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 10px;
}

.create-page-container {
    position: absolute;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: auto;
}
.create-container {
    width: 95%;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    text-align: left;
}
.create-left-container {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    order: 0;
}
.create-right-container {
    width: 40%;
    padding:20px;
    order: 1;
}
.create-form-container {
    width: 80%;
    padding: 30px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 15px;
    height: fit-content;
    margin: auto;
}
.create-form-width {
    width: 80%;
    padding-left: 30px;
    margin: auto;
}
.create-brand-page-container {
    background-color: rgba(57,187,236,0.05);
    height: 100%;
    border: 1px solid black;
    
}
.create-brand-page-logo-container {

}
.create-brand-page-form-container {
    border: 1px solid rgba(0,0,0,0.1);
    background-color: rgba(255,255,255,0.8);
    border-radius: 15px;
    padding: 30px 20px 20px 20px;
}
.home-page-cta-image {
    height: 300px;
    width: fit-content;
    margin: auto;
}

.login-form {
    max-width: 500px;
    margin: auto;
}
.login-form-forgot {
    float: right;
    color: #5662e2;
}
.login-field {
    width: 40%;
}
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: white;
    margin-top: 0px;
    padding: 20px;
    padding-top: 10vh;
}
.login-container{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-content {
    width: 80%;
    max-width: 1100px;
    margin: auto;
    text-align: left;
    display: flex;
    align-items: center;
    padding-bottom: 10vh;
}
.login-left-container {
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-right-container {
    width: calc(100% - 500px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 200px;
}
.login-page-title{
    font-size: 28px;
    font-weight: 600;
    color: rgb(0,0,0,0.7);
}
.login-title {
    font-size: 20px;
    font-weight: bold;
    text-decoration: underline;
}
.ant-col-rtl .login-form-forgot {
    float: left;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #5433FF;
    border-color: #5433FF;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #5433FF !important; 
    font-weight: 500;
}
.ant-tabs-tab.ant-tabs-tab-active:hover .ant-tabs-tab-btn:hover {
    color: #5433FF !important;
}
.ant-tabs-ink-bar {
    position: absolute;
    background: #5433FF !important;
    pointer-events: none;
}
.login-form-button {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0px;
    background-color: #5433FF !important;
}
.login-or{
    padding: 10px;
    text-align: center;
}
.persona-card-container{
    padding: 15px;
    color: rgba(0,0,0,0.8);
}
.persona-card-side {
    width: 50px;
    display: inline-block;
    vertical-align: middle;
}
.persona-card-logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid rgb(0,0,0,0.1);
}
.persona-card-context {
    width: calc(100% - 70px);
    display: inline-block;
    vertical-align: top;
    padding-left: 10px;
}
.persona-card-title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 3px;
    margin-bottom: 5px;
}
.persona-card-job {
    font-size: 13px;
}
.persona-card-text {
    margin-top: 7px;
    margin-bottom: 10px;
    font-size: 15px;
}

.pricing-plan-header {
    text-align: center;
    margin-bottom: 20px;
}
.pricing-plan-card-container {
    border-radius: 15px;
    padding: 30px;
    border: 1px solid rgba(86,98,226, 0.9);
}
.pricing-plan-card-title-container {
    margin-bottom: 10px;
}
.pricing-plan-card-title {
    line-height: 1.2;
    margin: 0;
    font-size: 1.5em;
}
.pricing-plan-card-price-container {
    display: flex;
    align-items: flex-end;
}
.pricing-plan-card-price {
    line-height: 1;
    margin: 0;
    font-size: 3em;
}
.pricing-plan-card-feature-container {
    font-size: 1.1em;
}
.pricing-plan-card-feature-list {
    padding: 15px;
    padding-left: 15px;
}
.pricing-plan-card-feature-list-item {
    line-height: 1.2;
    margin-bottom: 3px;
}
.pricing-plan-card-button{
    border: 1px solid rgba(86,98,226, 0.9) !important;
    background-color: rgba(86,98,226, 0.9) !important;
    color: white;
    border-radius: 20px;
    width: 100%;
}
.pricing-plan-card-button:hover {
    scale:1.02;
    border: 1px solid rgba(86,98,226, 0.9) !important;
    color: white !important;
}
.pricing-plan-card-button-disabled {
    border: rgba(0,0,0,0.2) !important;
    background-color: rgba(0,0,0,0.2) !important;
    color: white !important;
    border-radius: 20px;
    width: 100%;
}
.pricing-modal-bottom-banner {
    padding: 25px;
    border-radius: 15px;
    background-color: rgba(86,98,226, 0.5);
}

.post-instagram-container{
    max-width: 400px;
    background-color: white;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 5px;
    padding: 0 0 10px 0;
    font-family: sans-serif;
}
.post-instagram-header-container{
    display: flex;
    flex:1;
    padding: 5px;
    align-items: center;
}
.post-instagram-image-container{
    position: relative;
    border-top: 1px solid rgba(0,0,0,0.1);
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
.post-instagram-shuffle-image-style-button-container {
    position: absolute;
    top: 10px;
    left: 10px;
}
.post-instagram-content-container{
    text-align: left;
    padding: 5px 10px 5px 10px;
}
.post-instagram-poll-container {
    border-radius: 15px;
    width: 60%;
    max-width: 400px;
    overflow: hidden;
    margin-bottom: 10%;
    background-color: white;
}
.post-instagram-poll-question {
    background-color: #1f2023;
    padding: 10px;
    font-size: 15px;
    color: white;
    font-weight: 500;
    text-align: center;
}
.post-instagram-poll-option-container {
    padding: 10px 0 5px 0;
}
.post-instagram-poll-option {
    background-color: rgb(0,0,0,0.1);
    border-radius: 10px;
    padding: 5px;
    width: 90%;
    margin: auto;
    margin-bottom: 6px;
    cursor: pointer;
}
.post-instagram-generate-image-container{
    display: flex;
    align-items: center;
    padding: 10px 10px 15px 10px;
    background-color: rgba(29,161,242,0.2);
}
.post-instagram-download-post-container {
    display: flex;
    align-items: center;
    padding: 5px;
}
.post-instagram-icon-container {
    font-size: 16px;
    padding-bottom: 5px;
}
.post-instagram-likes {
    font-size: 14px;
    padding-bottom: 2px;
    font-weight: 600;
}
.post-instagram-username {
    font-size: 14px;
    font-weight: 600;
    margin-right: 3px;
}
.post-instagram-text {
    line-height: 1.3;
    white-space: pre-line;
}
.fa-instagram {
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100% );
    width: 100%;
    aspect-ratio: 1/1;
}
.post-instagram-image-text-container {
    width: 80%;
    margin: auto;
    background-color: rgba(0,0,0,0.8);
    padding: 20px 25px 20px 25px;
    border-radius: 15px;
}
.post-instagram-image-text-full-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.5);
}
.post-instagram-image-text {
    color: white;
    font-size: 2.3cqh;
    text-wrap: wrap;
}
.post-instagram-image-text-full {
    width: 80%;
    color: white;
    font-size: 4cqh;
    line-height: 1.5;
    text-wrap: wrap;
    margin-bottom: 20px;
}
.post-instagram-brand-slide {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 10%;
}
.post-instagram-brand-slide-card {
    border: 1px solid rgb(0,0,0,0.05);
    border-radius: 10px;
    background-color: rgb(250,250,250,0.6);
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.post-instagram-brand-text {
    margin-bottom: 10%;
    color: white;
}
.post-instagram-brand-follow-button {
    background-color: #0075ff;
    color: white;
    border-radius: 5px;
    font-weight: 400;
    padding: 2px 8px 2px 8px;
    width: fit-content;
    margin: auto;
    margin-top: 5px;
}
.post-card-container {
    padding: 25px;
    padding-bottom: 30px;
    border: 1px solid rgb(0,0,0,0.1);
    border-radius: 15px;
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
}
.post-card-side {
    width: 40px;
    display: inline-block;
    vertical-align: middle;
}
.post-card-logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid rgb(0,0,0,0.1);
}
.post-card-username {
    width: fit-content;
    display: inline-block;
    vertical-align: middle;
    padding-left: 7px;
    padding-bottom: 2px;
}
.post-card-name {
    font-weight: 600;
    font-size: 15px;    
    line-height: 1;
    margin-bottom: 0px;
}
.post-card-name-at {
    color: rgb(0,0,0,0.6);
    font-size: 14px;
    line-height: 1;
    margin-top: 0px;
}
.post-card-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
}
.post-card-button {
    color: rgb(29,161,242) !important; 
    border: 1px solid rgb(29,161,242) !important;
    border-radius: 10px !important;
    margin-top: 2px;
    margin-bottom: 5px;
    margin-right: 7px;
    height: auto !important;
    padding: 2px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.post-card-button:hover {
    padding-left: 11px !important;
    padding-right: 11px !important;
}
.post-card-twitter-image {
    border-radius: 5px;
    padding: 50px;
    aspect-ratio: 3/2;
    border: 1px solid rgba(0,0,0,0.2);
}
.post-card-poll-option {
    border: 1px solid rgb(29,161,242);
    color: rgb(29,161,242);
    border-radius: 15px;
    text-align: center;
    width: 80%;
    font-size: 15px;
    line-height: 1.2;
    padding: 5px;
    margin-bottom: 4px;
    cursor: pointer;
}
.post-card-poll-option:hover {
    background-color: rgb(29,161,242,0.05);
}
.post-twitter-image-field {
    border-radius: 10px;
    background-color: rgb(29,161,242);
    font-size: 14px;
    padding: 10px;
    width: 70%;
    aspect-ratio: 1/1;
}
.post-twitter-image-field-text {
    background-color: rgba(255,255,255,0.6);
    border-radius: 10px;
    padding: 15px;
    width: 80%;
    font-weight: 500;
}
.post-twitter-image-generate-button {
    background-color: #343541;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    padding: 2px 5px 2px 5px;
    width: fit-content;
    cursor:pointer;
}

.product-card-container {
    width: 60%;
    margin: auto;
    margin-bottom: 15px;
}
.product-card {
    background-color: #ffffff;
    border-radius: 10px;
    text-align: left;
    color: #343541;
}
.product-card-title {
    color: #343541;
    font-size: 1.5em;
    font-weight: 700;
}
.product-card-image-container {
    width: 40%;
    padding: 20px;
}
.product-card-image {
    border-radius: 10px;
}
.product-card-detail-container {
    width: 60%;
    padding: 20px;
}
.product-card-description {
    padding: 0 50px 0 0;
}
.product-card-price-discounted {
    text-decoration: line-through;
    color: rgba(0,0,0,0.4);
    font-size: 0.9em !important;
}
.product-card-price {
    font-size: 1.4em !important;
    color: rgb(76,204,132);
}
.product-card-button {
    border-radius: 10px !important;
}
.faq-container {
    width: 60%;
    margin:auto;
}
.faq-collapse {
    font-size:1.4em;
    font-weight: 500;
    color: white !important;
    padding:0;
}
.purchase-channel-container {
    padding: 30px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 15px;
    text-align: center;
}
.select-lg {
    font-size: 21px !important;
    font-weight: 600;
}
.project-create-image-delete{
    display: inline;
    position: absolute !important;
    top: 0.5em;
    left: 0.5em;
    background-color: white;
    padding: 2px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
}
.editor-container {
    padding-bottom: 20px;
}
.editor-menu {
    padding-bottom: 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.editor-menu-vertical {

}
.editor-content {
    padding: 10px;
    text-align: left;
}
.editor-button {
    border: 0px !important;
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.editor-button-style {
    display: inline-block;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
}
.more-link {
    color: white;
    font-size: 1.2em;
}
.more-link:hover {
    color: white;
    text-decoration: underline;
}
.bottom-arrow {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}


@media (prefers-color-scheme: dark) {
    body {
        background-color: #1f2023 !important;
        color: #dadada !important;
    }
}

/*
    Animations
*/
.bounce {
    animation: bounce 2s;
    animation-iteration-count: 1;
}
.bounce-sm {
    animation: bounce-sm 1s;
    animation-iteration-count: 1;
}
.reveal{
    transform: translateY(150px);
    opacity: 0;
    transition: 1s all ease;
}
.reveal.active{
    transform: translateY(0);
    opacity: 1;
}
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
}
@keyframes bounce-sm {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-4px);
    }
    60% {
      transform: translateY(-2px);
    }
}
@keyframes inAnimation {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
  
  @keyframes outAnimation {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
  

@media only screen and (max-width: 1000px) {
    .no-desktop {
        display: flex;
    }
    .no-mobile {
        display: none;
    }
    .mobile-sticky {
        position: sticky;
        top: 65px;
    }
    .mobile-full {
        width: 100% !important;
    }
    .mobile-full-width {
        width: 100% !important;
        margin-bottom: 10px;
    }
    .display-block-mobile {
        display: block !important;
    }
    .width-45 {
        width: 100%;
        margin: auto;
    }
    .bg-brand-ai {
        background-color: white;
    }
    .checkout-navbar {
        width:100%;
        padding: 20px 0 20px 0;
        background-color: #1f2023;
        z-index: 100;
    }
    .company-page-detail-container {
        padding-top: 0;
    } 
    .company-page-details-div {
        padding: 50px 10px 5px 12px;
    }
    .company-page-brand-info-container {
        padding: 10px;
        left: 0;
        right: 0;
        top: 50px;
        width: 100%;
        position: fixed;
    }
    .company-page-feed-tag-container {
        position: sticky;
        top: 110px;
        left: 0;
        padding: 0 10px 10px 10px;
        z-index: 100;
        overflow-x: auto;
    }
    .company-page-side-container {
        width: 100%;
        min-height: 0;
        padding: 0;
    }
    .company-page-feed-container {
        width: 100%;
        padding-left: 0;
    }
    .company-page-feed-content-container {
        padding-top: 10px;
    }
    .company-page-sidebar-bottom-info {
        width: 100%;
    }
    .container-fixed-left {
        position: relative;
        display: block;
        width: 100%;
    }
    .container-scroll-right {
        display: block;
        margin-left: 0%;
        width: 100%;
    }
    .checkout-detail-container {
        width: 80%;
        text-align: left;
        margin: auto;
        padding-top: 10vh;
        padding-bottom: 5vh;
    }
    .checkout-form-container {
        padding-left: 0;
        width: 90%;
        text-align: left;
        margin: auto;
    }
    .checkout-button-container {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 15px;
        background-color: #1f2023;
    }
    .content-card-container {
        border-radius: 20px;
    }
    .create-form-container {
        width: 95%;
        padding: 30px 20px 20px 20px;
    }
    .create-form-width {
        width: 90%;
        padding-left: 0;
    }
    .create-container {
        display: block;
    }
    .create-left-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .create-right-container {
        width: 100%;
        padding:20px;
    }
    

    .editor-menu {
        flex-wrap: nowrap;
    }
    .icon-container {
        width: 25%;
    }
    .home-page-section {
        padding: 10px
    }
    .home-page-banner-container {
        height: auto;
        display: block;
    }
    .home-page-left-container {
        width: 80%;
        margin: auto;
        height: 80%;
    }
    .home-page-right-container {
        width: 100%;
    }
    .home-page-left-content {
        padding-top: 10vh;
    }
    .home-page-right-content {
        position: relative;
        top: 0;
    }
    .home-page-details-container {
        display: block;
        padding: 5px !important;
        width: 90%;
    }
    .home-page-details-section {
        width: 100%;
        padding: 5px;
    }
    .home-page-detail-card-title-container {
        padding-top: 25px;
        padding-left: 20px;
    }
    .home-page-video-container {
        display: none;
    }
    .home-page-video-description {
        padding: 0 20px 20px 20px;
        color: #526373;
    }
    .home-page-brand-card {
        width: 50%;
        padding: 0;
    }
    .home-page-form-mobile-container {
        width: 95% !important;
    }
    .home-page-cta-image {
        width: 60% !important;
        margin: auto;
        height: auto;
    }
    .login-container {
        width: 100%;
        overflow: auto;
    }
    .login-content {
        display: block;
        width: 95%;
    }
    .login-left-container{
        display: block;
        width: 90%;
        margin: auto;
        padding-top: 12vh;
        padding-bottom: 5vh;
    }
    .login-right-container{
        width: 100%;
        display: block;
        text-align: center;
        padding: 0px;
        padding-top: 5vh;
    }
    .faq-container {
        width: 98%;
    }
    .feed-create-modal-md {
        width: 100%;
        height: auto;
    }
    .feed-create-modal-header {

    }
    .navbar-container {
        padding: 0 10px 0 10px;
    }
    .post-card-poll-option {
        width: 100%;
    }
    .product-card-container {
        width: 95%;
    }
    .product-card-image-container {
        width: 100%;
        margin: auto;
    }
    .product-card-detail-container {
        padding: 0;
    }
    .product-card-description {
        padding: 10px 0 0 0;
    }
}