.logo_container {
    height: 65px;
    padding-left: 20px;
    display: flex;
    align-items: center;
}
.sidebar_menu_container {
    padding: 20px;
}

.dashboard_container {
    position: fixed;
    left: 250px;
    top: 0;
    right: 0;
    bottom:0;
}
.dashboard_top_bar {
    position: fixed;
    top: 0;
    left: 250px;
    right: 0;
    height: 65px;
    display: flex;
    align-items: center;
}
.dashboard_top_bar_title_container {
    text-align: left;
}
.dashboard_top_bar_title {
    margin: 0;
    line-height: 1;
    padding: 0 15px 0 15px;
    color: rgba(0,0,0,0.8);
}
.dashboard_top_bar_menu_container {
    display: flex;
    align-items: center;
}
.dashboard_top_bar_mobile_menu_icon {
    display: none;
}
.dashboard_top_bar_search_container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dashboard_top_bar_search {
    width: 60%;
}
.dashboard_top_bar_notification_container {
    padding-left: 15px;
}
.dashboard_top_bar_user_container {
    padding-left: 15px;
    padding-right: 25px;
}
.dashboard_user_container {
    display: flex;
    align-items: center;
    padding: 2px 12px 2px 5px;
    cursor: pointer;
}
.dashboard_user_container:hover {
    border-radius: 10px;
    background-color: #F9FAFB;
}
.dashboard_user_avatar_container {
    padding: 5px;
}
.dashboard_user_avatar {
    width: 35px;
    height: 35px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 10px;
}
.dashboard_user_link {
    color: rgba(86,98,226,1);
}
.dashboard_user_text_container {
    text-align: left;
}
.dashboard_username {
    color: rgba(0,0,0,0.8);
    padding-right: 10px;
}
.dashboard_username_dropdown {
    color: rgba(0,0,0,0.6);
    font-size: 12px;
    display: flex;
    align-items: center;
}
.dashboard_user_title {
    color: rgba(0,0,0,0.5);
    font-size: 10px;
}

/*
    Brand page
*/

.dashboard_brand_page_container {
    width: 100%;
    max-width: 800px;
}
.dashboard_brand_container {
    display: flex;
    align-items: center;
    padding-top: 10px;
    margin-bottom: 5px;
}
.dashboard_brand_avatar {
    width: 42px;
    height: 42px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 10px;
}
.dashboard_brand_name {
    line-height: 1.2;
    font-weight: 600;
    padding-left: 5px;
}
.dashboard_brand_create_container{
    font-size: 1.2em;
    color: rgba(86,98,226,0.8);
    border: 1px solid rgba(86,98,226,0.2);
    border-radius: 10px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dashboard_brand_create_container:hover {
    scale: 1.02;
}

.brand_preview_card_container {
    padding: 20px;
}
.brand_preview_card_name_container {
    padding: 10px;
    width: fit-content;
}
.dashboard_brand_preview_avatar {
    width: 70px;
    height: 70px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 10px !important;
}
.dashboard_brand_preview_name {
    margin: 0;
    line-height: 1.2;
}
.dashboard_brand_preview_tagline {
    margin: 0;
    line-height: 1.2;
}
.brand_preview_card_detail_container {
    padding: 10px;
    font-size: 16px;
    line-height: 1.2;
}
.brand_preview_card_description {
    padding-left: 10px;
}
.brand_preview_card_detail_section {
    padding-left: 10px;
}


.dashboard_sidebar {
    position: fixed;
    width: 250px;
    left: 0;
    top: 0;
    bottom:0;
}
.dashboard_main_container {
    position: fixed;
    right: 0;
    left: 250px;
    top: 65px;
    bottom:0;
    flex:1;
    background-color: #F9FAFB;
}
.dashboard_menu_button_container {
    padding: 5px;
}
.dashboard_menu_button {
    width: 100%;
    padding: 15px;
    text-align: left;
    font-size: 16px;
    cursor: pointer;
    color: rgb(0,0,0,0.6);
    border-radius: 10px;
    display: flex;
}
.dashboard_menu_bottom_button {
    position: absolute;
    bottom: 0;
    width: 100%;
}
.dashboard_menu_button:hover {
    background-color: rgba(86,98,226,0.1);

}
.dashboard_menu_button.active {
    background-color: rgba(86,98,226,1);
    font-weight: 600;
    color: white;
}
.dashboard_menu_button_icon {
    font-size: 18px;
}

.dashboard_coming_soon_container {
    width: 100%;
    height: 90%;
    padding: 10px;
}
.dashboard_coming_soon_text {
    color: rgba(86,98,226,1);
    font-size: 3em;
}
.dashboard_coming_soon_subtext {
    color: rgba(0,0,0,0.8);
    font-weight: 500;
}
.dashboard_button_container {
    padding-top: 3vh;
    padding-bottom: 10vh;
}

/*
    Feed Page
*/

.dashboard_feed_filter_menu_container {
    display: flex;
    text-align: left;
    flex: 1;
    color: rgba(86,98,226,1);
}
.dashboard_feed_filter_button_container {
    padding: 10px 0 10px 15px;
}

.dashboard_feed_select_page_container {
    width: 100%;
    max-width: 1200px;
}
.dashboard_feed_preview_filter_container {
    width: 100%;
    padding: 10px;
}
.dashboard_feed_results_count_container {
    width: fit-content;
    color: rgba(0,0,0,0.4);
    padding-left: 15px;
    text-decoration: underline;
}
.dashboard_feed_preview_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 15vh;
}
.dashboard_feed_preview_card_container {
    padding: 10px;
    width: 25%;
}
.dashboard_feed_preview_card {
    width: 100%;
    cursor: pointer;
}
.dashboard_feed_preview_card_image {
    width: 100%;
    aspect-ratio: 2/1;
    border-radius: 5px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.dashboard_feed_preview_card_detail_container {
    padding: 10px 0 0 0;
    text-align: left;
    color: rgba(0,0,0,0.7);
}
.dashboard_feed_preview_card_title {
    font-size: 1.2em;
    margin: 0;
    line-height: 1.2;
    font-weight: 600;
}
.dashboard_feed_preview_card_subtitle {
    margin: 0;
    line-height: 1.2;
    color: rgba(0,0,0,0.5);
    font-weight: 600;
}
.dashboard_feed_card {
    padding: 15px 20px 20px 20px;
    position: relative;
    height: 100%;
}
.dashboard_feed_create_card_container {
    font-size: 1.2em;
    color: rgba(86,98,226,1);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100%;
}
.dashboard_feed_create_card_container:hover {
    border: 1px solid rgba(86,98,226,0.1);
    scale: 1.03;
}
.dashboard_feed_create_icon_container {
    font-size: 2em;
    padding: 15px;
}

.dashboard_feed_page_container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    padding: 10px;
}
.dashboard_feed_container_md {
    width: 60%;
    max-width: 600px;
    padding: 10px;
    order: 0;
}
.dashboard_feed_container_sm {
    max-width: 400px;
    margin: auto;
}
.dashboard_feed_filter_container {
    order: 1;
    width: 40%;
    max-width: 350px;
    position: sticky;
    top: 0;
    padding: 10px;}

.dashboard_filter_sidebar_container {
    padding: 20px;
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &&::-webkit-scrollbar {
        display: none;
    }
}
.dashboard_sidebar_feed_avatar {
    width: 50px;
    height: 50px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 10px;
    margin-right: 10px;
}
.dashboard_sidebar_feed_title {
    margin: 0;
    line-height: 1.2;
}
.dashboard_sidebar_feed_brand {
    margin: 0;
    line-height: 1.2;
    color: rgba(0,0,0,0.4);
    padding-bottom: 3px;
}

/*
    Settings Page
*/
.dashboard_settings_container {
    padding: 20px 40px 40px 40px;
}
.dashboard_settings_input_container {
    width: 80%;
    margin-top: 5px;
    margin-bottom: 10px;    
}
.dashboard_settings_subtitle {
    line-height: 1.5;
    padding-top: 5px;
    margin-bottom: 5px;
}
.dashboard_settings_button_container {
    padding-top: 15px;
    padding-bottom: 15px;
}






@media only screen and (max-width: 1200px) {

    .dashboard_feed_preview_card_container {
        width: 33%;
    }

}

@media only screen and (max-width: 1000px) {
    .logo_container {
        padding: 0;
        padding-bottom: 10px;
    }
    .sidebar_menu_container {
        padding: 0;
    }

    .brand_preview_card_container {
        padding: 20px 10px 20px 10px;
    }
    .dashboard_sidebar {
        display: none;
    }
    .dashboard_container {
        left: 0;
    }
    .dashboard_top_bar {
        left: 0;
    }
    .dashboard_main_container {
        left: 0;
    }
    .dashboard_top_bar_search_container {
        display: none;
    }
    .dashboard_top_bar_mobile_menu_icon_container {
        padding: 5px;
    }
    .dashboard_top_bar_mobile_menu_icon {
        display: block;
        color: rgba(86,98,226,1);
        background-color: rgba(86,98,226,0.1);
        border-radius: 5px;
        font-size: 16px;
        padding: 10px;
    }
    .dashboard_top_bar_title_container {
        flex: 1;
    }
    .dashboard_top_bar_user_container {
        padding: 0;
    }
    .dashboard_feed_card {
        padding: 15px;
    }
    .dashboard_feed_preview_card {

    }
    .dashboard_feed_preview_filter_container {
        padding: 5px;
    }
    .dashboard_feed_preview_card_container {
        padding: 5px;
        width: 100%;
    }
    .dashboard_feed_page_container {
        display: block;
        padding: 10px;
        margin-bottom: 5px;
    }
    .dashboard_feed_container_md {
        width: 100%;
        padding: 0;
    }
    .dashboard_feed_filter_container {
        width: 100%;
        max-width: none;
        position: relative;
        padding: 0;
        margin-bottom: 10px;
    }    
    .dashboard_filter_sidebar_container {
        position: relative;
        padding: 5px 15px 15px 15px;
        border: 1px solid rgba(0,0,0,0.1);
        border-radius: 10px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.01), 0 1px 5px 0 rgba(0, 0, 0, 0.03);
    }
    .dashboard_filter_category {
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 10px;
    }
    .dashboard_user_container {
        align-items: flex-start;
    }
    .dashboard_brand_preview_name {
        line-height: 1;
        margin-bottom: 3px;
    }
    .dashboard_brand_preview_tagline {
        line-height: 1.1;
    }
    .brand_preview_card_name_container {
        padding-bottom: 5px;
    }
}